import axios from 'axios';

import { mainApiUrl, teamUrl } from '@configs';

/**
 * Storing access token inside memory.
 */
// let accessToken = '';

/**
 * Custom Axios with default configuration
 */
const customAxios = axios.create();
customAxios.defaults.withCredentials = true;
customAxios.defaults.validateStatus = () => true;

/**
 * Cancel token used to cancel a request.
 */
let source = axios.CancelToken.source();
customAxios.defaults.cancelToken = source.token;

/**
 * Before axios request is sent
 */
customAxios.interceptors.request.use((config) => {
  const newConfig = { ...config };
  if (typeof window !== 'undefined') {
    const ctoken = localStorage.getItem('ctoken');
    if (ctoken) {
      const headers = { ...newConfig.headers, ctoken };
      newConfig.headers = headers;
    }
  }
  return newConfig;
}, (error) => Promise.reject(error));

/**
 * After axios received response
 */
customAxios.interceptors.response.use((response) => {
  if (response?.headers?.ctoken) {
    localStorage.setItem('ctoken', response.headers.ctoken);
  }
  // If receive 401 please check whether the user can request new access token
  if (response.status === 401) {
    if (response.data.error.expired) {
      const originalConfig = response.config;
      return customAxios({
        method: 'post',
        url: `${mainApiUrl}/auth/refresh`,
      }).then((res) => {
        if (res.status === 401) {
          return response;
        }
        return customAxios(originalConfig);
      }).catch(() => response);
    }
    if (response.data.error.login) {
      window.location.href = `${teamUrl}/login`;
    }
  }
  return response;
}, (error) => Promise.reject(error));

/**
 * Cancel all request on this axios module.
 */
export const cancelRequest = () => {
  source.cancel();
  source = axios.CancelToken.source();
  customAxios.defaults.cancelToken = source.token;
};

export default customAxios;
