import { MapString } from '@type';

interface GaEvent {
  action: string;
  params: MapString;
}

// log the pageview with their URL
export const pageview = (url: string) => {
  if (!window.gtag) return;
  window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  });
};

// log specific events happening.
export const event = ({ action, params }: GaEvent) => {
  if (!window.gtag) return;
  window.gtag('event', action, params);
};
