/**
 * React environment : dev | stg | prod
 */
const reactENV = process.env.NEXT_PUBLIC_ENV || '';

const domainList: { [key: string]: string; } = {
  '': 'http://localhost:8383',
  dev: 'https://algo.kekal.id',
  stg: 'https://algo.kekal.id',
  prod: 'https://www.algobash.com',
};

const courseDomainList: { [key: string]: string; } = {
  '': 'http://localhost:8282',
  dev: 'https://c.algo.kekal.id',
  stg: 'https://c.algo.kekal.id',
  prod: 'https://course.algobash.com',
};

/**
 * Domain for meta tags
 */
export const domain = domainList[reactENV];
export const courseDomain = courseDomainList[reactENV];

const gatewayHosts: { [key: string]: string; } = {
  '': `http://${typeof window === 'undefined' ? 'api-gateway-nginx' : 'localhost:8082'}`,
  dev: 'https://api.algox.kekal.id',
  stg: 'https://api.algox.kekal.id',
  prod: 'https://api.algobash.com',
};

/**
 * Main url for Team
 */
export const gatewayHostUrl = gatewayHosts[reactENV];

const teamUrls: { [key: string]: string; } = {
  '': 'http://localhost:3008',
  dev: 'https://team.algo.kekal.id',
  stg: 'https://team.algo.kekal.id',
  prod: 'https://team.algobash.com',
};

/**
 * Main url for Team
 */
export const teamUrl = teamUrls[reactENV];

const appUrls: { [key: string]: string; } = {
  '': 'http://localhost:3010',
  dev: 'https://app.algo.kekal.id',
  stg: 'https://app.algo.kekal.id',
  prod: 'https://app.algobash.com',
};

/**
 * Main url for App
 */
export const appUrl = appUrls[reactENV];

/**
 * Main URL for gateway.
 */
export const mainApiUrl = `${gatewayHostUrl}/api/v1`;
export const teacherApiUrl = `${gatewayHostUrl}/api/v1/t`;
export const studentApiUrl = `${gatewayHostUrl}/api/v1/s`;
export const graphqlUrl = `${gatewayHostUrl}/graphql`;

/**
 * Allowed Billing Cycles
 */
export const allowedBillingCycles = ['annually', 'quarterly', '6-monthly'] as const;

/**
 * List of available gender for user registration.
 */
export const genderConf = [
  'female',
  'male',
  'undisclosed',
] as const;

/**
 * Analytics & Tag manager
 */
export const analyticsId = 'AW-308894212';
export const gtagId = 'GTM-WLRBMC7';
