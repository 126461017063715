import {
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { SentryLink } from 'apollo-link-sentry';
import { graphqlUrl } from '@configs';

const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: from([
    new SentryLink(),
    createUploadLink({ uri: graphqlUrl, credentials: 'include' }),
  ]),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default client;
