/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import moment from 'moment';
import App, {
  AppProps,
  AppContext,
} from 'next/app';
import SwiperCore, {
  Navigation,
} from 'swiper';
import { appWithTranslation } from 'next-i18next';
import { Router } from 'next/router';
import NProgress from 'nprogress';
import { ApolloProvider } from '@apollo/client';

import axios from '@modules/axios';
import { getAuthInfo } from '@resources/auth';
import {
  UserProfile,
  UserProfileProvider,
} from '@reducers/user';
import client from '@graphql/client';

import 'swiper/css';
import 'swiper/css/navigation';
import 'moment/locale/id';
import '../../styles/index.css';
import { pageview } from '@helpers/analytics';

SwiperCore.use([Navigation]);

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const MyApp = (props: AppProps & UserProfile) => {
  const {
    Component,
    pageProps,
    router,
    profile,
    fullUrl,
  } = props;

  moment.locale(router.locale);

  useEffect(() => {
    if (router.locale) {
      axios.defaults.headers.common['accept-language'] = router.locale;
    }
  }, [router.locale]);

  /**
   * Log pageviews
   */
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ApolloProvider client={client}>
      <UserProfileProvider
        profile={profile}
        fullUrl={fullUrl}
        countryId={pageProps.countryId}
      >
        <Component {...pageProps} />
      </UserProfileProvider>
    </ApolloProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const {
    ctx: { req, res, pathname },
    router: { locale, asPath },
  } = appContext;

  const appProps = await App.getInitialProps(appContext);

  if (locale) {
    axios.defaults.headers.common['accept-language'] = locale;
  }
  if (req) {
    axios.defaults.headers.common.cookie = req.headers.cookie || '';
  }

  // Protect hidden pages
  if (pathname.includes('_') && res) {
    res.writeHead(301, { location: '/404' });
    res.end();
  }

  // Get full url
  let fullUrl = '';
  if (req) {
    const host = (req.headers['x-forwarded-host'] as string) || req.headers.host;
    const prtc = host?.includes('localhost') ? 'http://' : 'https://';
    fullUrl = prtc + host + asPath;
  } else {
    fullUrl = window.location.href;
  }

  return {
    ...appProps,
    fullUrl,
    profile: (await getAuthInfo())?.data,
  };
};

export default appWithTranslation(MyApp);
