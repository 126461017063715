var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"n_ksjzQDpR85RGOnOChiz"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// // This file configures the initialization of Sentry on the browser.
// // The config you add here will be used whenever a page is visited.
// // https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const APP_ENV = process.env.NEXT_PUBLIC_ENV;

const gatewayHosts = {
  dev: 'https://api.algox.kekal.id/s',
  stg: 'https://api.algox.kekal.id/s',
  prod: 'https://api.algobash.com/s',
};

if (APP_ENV && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tunnel: gatewayHosts[APP_ENV],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: APP_ENV,
    ignoreErrors: [
      'Network request failed',
      'Failed to fetch',
      'NetworkError',
      'Network Error',
      'withrealtime/messaging',
    ],
    allowUrls: [
      'https://api.algo.kekal.id',
      'https://api.stg-algo.kekal.id',
      'https://api.algobash.com',
      'http://localhost:8081',
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
