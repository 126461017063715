import axios from '@modules/axios';
import { mainApiUrl, teacherApiUrl } from '@configs';
import { APIHTTPResponse, Profile } from '@type';

/**
 * Login user (collaborator)
 * @param email User email
 * @param password Password
 * @param keepSession Check if session should be longer
 */
export const postLogin = async (
  email: string,
  password: string,
  keepSession = false,
) => {
  try {
    const { data: response } = await axios.request<APIHTTPResponse>({
      url: `${teacherApiUrl}/auth/login`,
      method: 'post',
      data: {
        email,
        password,
        keepSession,
      },
    });

    return response;
  } catch (e) {
    return null;
  }
};

/**
 * Get auth info (profile) credentials from server
 */
export const getAuthInfo = async () => {
  try {
    const { data: response } = await axios.request<APIHTTPResponse<Profile>>({
      url: `${mainApiUrl}/auth/info`,
      method: 'get',
    });

    return response;
  } catch (e) {
    return null;
  }
};

/**
 * Log out user and remove session cookie from API.
 * @param fcm_token Firebase messaging token
 */
export const postLogoutUser = async (fcmToken?: string) => {
  try {
    const { data: response } = await axios.request<APIHTTPResponse>({
      url: `${mainApiUrl}/auth/signout`,
      method: 'post',
      data: { fcm_token: fcmToken },
    });

    return response;
  } catch (e) {
    return null;
  }
};
