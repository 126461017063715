import React, {
  FunctionComponent,
  createContext,
  useContext,
} from 'react';

import { Profile } from '@type';

/**
 * UserProfile reducer type
 */
interface UserProfile {
  profile: Profile | null;
  fullUrl: string;
  countryId: string | undefined
}

/**
 * UserProfile Context
 */
const UserContext = createContext<UserProfile>({
  profile: null,
  fullUrl: '',
  countryId: undefined,
});

/**
 * Provider for UserProfile, used in _app.tsx
 */
const UserProfileProvider: FunctionComponent<UserProfile> = (props) => {
  const {
    children, profile, fullUrl, countryId,
  } = props;

  return (
    <UserContext.Provider value={{ profile, fullUrl, countryId }}>
      {children}
    </UserContext.Provider>
  );
};

/**
 * Context consumer, used in MainNavbar component
 */
const useUserProfile = () => useContext(UserContext);

export { UserContext, UserProfileProvider, useUserProfile };
export type { UserProfile };
